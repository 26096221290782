<template>
  <b-card title="Setting">
    <b-tabs>
      <b-tab
        :active="currentRouteName=='add-user'"
        @click="$router.push('/add-user')"
      >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>User Management</span>
        </template>

      </b-tab>
      <b-tab
        :active="currentRouteName=='links-redirection'"
        @click="$router.push('/links-redirection')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Links Redirection  </span>
        </template>

        <!-- <LinkRedirication></LinkRedirication> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='links-redirection-arabic'"
        @click="$router.push('/links-redirection-arabic')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Links Redirection Arabic</span>
        </template>

        <!-- <LinkRediractionArabic></LinkRediractionArabic> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='courses-durations'"
        @click="$router.push('/courses-durations')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span> Courses Durations</span>
        </template>

        <!-- <CoursesDuration></CoursesDuration> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='analytic-codes'"
        @click="$router.push('/analytic-codes')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span> Analytic Codes</span>
        </template>

        <!-- <AnalyticCodes></AnalyticCodes> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='zoho-integration'"
        @click="$router.push('/zoho-integration')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" data-testid="zoho"/>
          <span>ZOHO Integration</span>
        </template>

        <!-- <ZOHOIntegration></ZOHOIntegration> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='home-page-section'"
        @click="$router.push('/home-page-section')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Home Page Sections</span>
        </template>

        <!-- <HomePageSections></HomePageSections> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='copying-protection'"
        @click="$router.push('/copying-protection')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Copying Protection</span>
        </template>

        <!-- <CopyProtection></CopyProtection> -->
      </b-tab>

    </b-tabs>

    <router-view v-slot="{ Component, route }">
      <indexUser v-if="route.name === 'setting-index' || route.name === 'index-user' " />
      <router-view v-else />
    </router-view>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import indexUser from './usermanagemnet/index.vue'
import LinkRedirication from './LinkRedirication.vue'
import LinkRediractionArabic from './LinkRediractionArabic.vue'
import CoursesDuration from './CoursesDuration.vue'
import AnalyticCodes from './AnalyticCodes.vue'
import ZOHOIntegration from './ZOHOIntegration.vue'
import HomePageSections from './HomePageSections.vue'

import CodeGenerator from './CodeGenerator.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

    indexUser,
    LinkRedirication,
    LinkRediractionArabic,
    CoursesDuration,
    AnalyticCodes,
    ZOHOIntegration,
    HomePageSections,

    CodeGenerator,
  },
  data() {
    return {

    }
  },

  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>
